import { isSunday, nextSunday } from 'date-fns';
import { HYMNS_DATA } from './hymns.data';
import type { SacramentProgram } from '../models/sacrament-program';
import { getHymn } from './utils';
import { HymnLanguage } from '../models/hymn';

const today = new Date();
const todayIsSunday = isSunday(today);

export const SACRAMENT_PROGRAM_DATA: SacramentProgram = {
  id: 1,
  date: todayIsSunday ? new Date() : nextSunday(new Date()),
  startTime: '10:30 AM',
  endTime: '11:30 AM',
  address: {
    reference: '1178 S Pondside Dr, Saratoga Springs, UT 84045',
    link: 'https://maps.app.goo.gl/4LxgucSVvEYa7pem7',
  },
  presiding: {
    name: 'Bishop Name',
    details: 'Saratoga 9th Ward, Bishop',
  },
  conducting: {
    name: 'Brother Salesi Sika',
    details: 'Saratoga 9th Ward, Bishopric First Counselor',
  },
  chorister: {
    name: 'Brother Chorister',
    details: 'Ward Member',
  },
  organist: {
    name: 'Sister Pianist',
    details: 'Ward Member',
  },
  invocation: {
    name: 'Brother Person',
    details: 'Ward Member',
  },
  openingSong: getHymn(122, HymnLanguage.tongan),
  sacramentSong: getHymn(202, HymnLanguage.tongan),
  // restSong: getHymn(206, HymnLanguage.english),
  testimonySunday: false,
  speakers: [
    {
      index: 0,
      role: 'speaker',
      beforeRestHymn: true,
      person: {
        name: 'Youth Person 1',
        details: 'Youth ward member',
      },
    },
    {
      index: 1,
      role: 'musical',
      beforeRestHymn: true,
      person: {
        name: 'Brother Person 1 - Give Your Love Away',
        details: 'Ward member',
      },
    },
    {
      index: 2,
      role: 'speaker',
      beforeRestHymn: true,
      person: {
        name: 'Sister Person 2',
        details: 'Ward member',
      },
    },
    {
      index: 3,
      role: 'musical',
      beforeRestHymn: true,
      person: {
        name: 'Brother Person 3 - It Came Upon The Midnight Clear',
        details: 'Ward member',
      },
    },
    {
      index: 4,
      role: 'speaker',
      beforeRestHymn: true,
      person: {
        name: 'Brother Person 4',
        details: 'Ward member',
      },
    },
  ],
  closingSong: getHymn(210, HymnLanguage.english),
  benediction: {
    name: 'Brother Person',
    details: 'Ward Member',
  },
  stakeBusinessAssignment: {
    name: 'Brother Person',
    details: 'Stake High Councilor',
  },
};
