const HYMNS_TON = [
  {
    page: 1,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-morning-breaks?lang=ton',
    title: 'Kuo ʻAho Hake ʻa e Pō',
  },
  {
    page: 2,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-spirit-of-god?lang=ton',
    title: 'Ko e Laumālie ʻo e ʻOtuá',
  },
  {
    page: 3,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/now-let-us-rejoice?lang=ton',
    title: 'ʻE Kāinga Tau Loto-fiemālie',
  },
  {
    page: 4,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/high-on-the-mountain-top?lang=ton',
    title: 'Vakai ʻe Kāinga',
  },
  {
    page: 5,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/redeemer-of-israel?lang=ton',
    title: 'Huhuʻi ʻo ʻIsileli',
  },
  {
    page: 6,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/israel-israel-god-is-calling?lang=ton',
    title: 'ʻIsileli, ʻIsileli, ʻOku Ui ʻa e ʻOtuá',
  },
  {
    page: 7,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/what-was-witnessed-in-the-heavens?lang=ton',
    title: 'Ko e hā Ne Hā ʻi Langí?',
  },
  {
    page: 8,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/an-angel-from-on-high?lang=ton',
    title: 'Kuo Puna ʻa e ʻĀngelo',
  },
  {
    page: 9,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-ever-pray-for-thee?lang=ton',
    title: 'ʻOku Mau Lotua ʻa e Palōfitá',
  },
  {
    page: 10,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-thank-thee-o-god-for-a-prophet?lang=ton',
    title: 'Fakamālō ki he ʻOtua',
  },
  {
    page: 11,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-bless-our-prophet-dear?lang=ton',
    title: 'ʻOtua Tāpuakiʻi Siʻomau Palōfita ʻOfeiná',
  },
  {
    page: 12,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-listen-to-a-prophets-voice?lang=ton',
    title: 'Mou Omi ʻo Fanongoa',
  },
  {
    page: 13,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-listen-to-a-prophets-voice?lang=ton',
    title: 'Fanongo ki he Palōfita',
  },
  {
    page: 14,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/joseph-smiths-first-prayer?lang=ton',
    title: 'Ko e Fua Lotu ʻa Siosefá',
  },
  {
    page: 15,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/merrily-merrily-over-the-sea?lang=ton',
    title: 'Ko Sīsū ko e Maka-tuliki',
  },
  {
    page: 16,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/praise-to-the-man?lang=ton',
    title: 'ʻOku Mau Fakamālō',
  },
  {
    page: 17,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/a-poor-wayfaring-man-of-grief?lang=ton',
    title: 'Ko ha Tangata Fononga Fonu he Loto-mamahi',
  },
  {
    page: 18,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-come-ye-saints?lang=ton',
    title: 'Haʻu Kāinga ʻOua Manavahē',
  },
  {
    page: 19,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-ye-mountains-high?lang=ton',
    title: 'Ko e Moʻunga ʻo Saione',
  },
  {
    page: 20,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/for-the-strength-of-the-hills?lang=ton',
    title: 'ʻI he Mālohi ʻo Moʻungá',
  },
  {
    page: 21,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/hail-to-the-brightness-of-zions-glad-morning?lang=ton',
    title: 'Kāingalotu Tau Hiva Hōsana',
  },
  {
    page: 22,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/beautiful-zion-built-above?lang=ton',
    title: 'ʻE Saione ʻi ʻOlunga',
  },
  {
    page: 23,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-o-thou-king-of-kings?lang=ton',
    title: 'Haʻu ʻa e Tuʻi ʻo e Ngaahi Tuʻi',
  },
  {
    page: 24,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-ye-children-of-the-lord?lang=ton',
    title: 'Haʻu e Fānau ʻa e ʻEiki',
  },
  {
    page: 25,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/take-heed-ye-saints?lang=ton',
    title: 'Siʻoku Kāinga ke Mou Tokanga',
  },
  {
    page: 26,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-clarion-call-of-the-gospel?lang=ton',
    title: 'ʻOku Fafangu Mai ʻa e Ongoongoleleí',
  },
  {
    page: 27,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/thus-sayeth-the-lord?lang=ton',
    title: 'Folofola Mai ʻa Sīsū',
  },
  {
    page: 28,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/all-creatures-of-our-god-and-king?lang=ton',
    title: 'ʻA e Meʻa Moʻui Kotoa pē',
  },
  {
    page: 29,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/for-the-beauty-of-the-earth?lang=ton',
    title: 'Māmani Fakaʻofoʻofa',
  },
  {
    page: 30,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/a-mighty-fortress-is-our-god?lang=ton',
    title: 'ʻOtua Ko e Fakaū',
  },
  {
    page: 31,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/rejoice-the-lord-is-king?lang=ton',
    title: 'Fiefia, ko e ʻEiki ko e Tuʻi',
  },
  {
    page: 32,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/in-hymns-of-praise?lang=ton',
    title: 'Hiki Hake Homou Leʻo',
  },
  {
    page: 33,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/all-glory-laud-and-honor?lang=ton',
    title: 'Ko e Nāunau pea mo e Ngeiá',
  },
  {
    page: 34,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-of-our-fathers-whose-almighty-hand?lang=ton',
    title: 'ʻOtua ʻo ʻEmau Ngaahi Tamai',
  },
  {
    page: 35,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/press-forward-saints?lang=ton',
    title: 'Tau Laka Atu he Tui Kia Kalaisi',
  },
  {
    page: 36,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/for-all-the-saints?lang=ton',
    title: 'Maʻá e Kau Māʻoniʻoni',
  },
  {
    page: 37,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-firm-a-foundation?lang=ton',
    title: 'ʻE Kāinga Kuo Langa Ha Tuʻunga',
  },
  {
    page: 38,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-great-thou-art?lang=ton',
    title: 'ʻE ʻOtua ko e Tapuhā',
  },
  {
    page: 39,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/prayer-of-thanksgiving?lang=ton',
    title: 'Ko e Lotu ʻo e Fakafetaʻi',
  },
  {
    page: 40,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-ye-thankful-people?lang=ton',
    title: 'Mou Haʻu Hoku Kakai',
  },
  {
    page: 41,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/my-god-the-spring-of-all-my-joys?lang=ton',
    title: 'ʻE ʻOtua ko e Tupuʻanga',
  },
  {
    page: 42,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/glory-to-god-on-high?lang=ton',
    title: 'Kolōlia ki he ʻOtua',
  },
  {
    page: 43,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/guide-us-o-thou-great-jehovah?lang=ton',
    title: 'ʻE Sihova Haofaki',
  },
  {
    page: 44,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-is-love?lang=ton',
    title: 'Ko e ʻOtuá ko e ʻOfa',
  },
  {
    page: 45,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-lord-is-my-light?lang=ton',
    title: 'Ko e ʻEikí ko ʻEku Maama',
  },
  {
    page: 46,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-we-that-love-the-lord?lang=ton',
    title: 'Haʻu ʻa Kitautolu ʻoku ʻOfa he ʻEikí',
  },
  {
    page: 47,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/lead-kindly-light?lang=ton',
    title: 'Kosipeli Ko e Maama Lelei',
  },
  {
    page: 48,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-need-thee-every-hour?lang=ton',
    title: 'ʻOku Ou Fie Maʻu Koe',
  },
  {
    page: 49,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/nearer-my-god-to-thee?lang=ton',
    title: 'Ofi Hoku ʻOtuá',
  },
  {
    page: 50,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/precious-savior-dear-redeemer?lang=ton',
    title: 'ʻOku Maʻongoʻonga ē Hoʻo ʻOfa',
  },
  {
    page: 51,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/master-the-tempest-is-raging?lang=ton',
    title: 'Naʻe Tala pē ʻe Sīsū',
  },
  {
    page: 52,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-lord-is-my-keeper?lang=ton',
    title: 'Ko Hoku Tauhi ʻa Sihova',
  },
  {
    page: 53,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-unto-jesus?lang=ton',
    title: 'Haʻu Kia Sīsū',
  },
  {
    page: 54,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/lord-accept-our-true-devotion?lang=ton',
    title: 'ʻEiki ko ʻEmau Kole',
  },
  {
    page: 55,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-lord-is-my-shepherd?lang=ton',
    title: 'Ko e ʻEiki ko e Tauhi-sipi',
  },
  {
    page: 56,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/our-saviors-love?lang=ton',
    title: 'Ko e ʻOfa ʻa e Fakamoʻuí',
  },
  {
    page: 57,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-follow-me?lang=ton',
    title: 'Muimui ʻIate Au',
  },
  {
    page: 58,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/if-the-way-be-full-of-trial?lang=ton',
    title: 'Ka Faingataʻa Ho Hala Feinga',
  },
  {
    page: 59,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-gentle-gods-commands?lang=ton',
    title: 'ʻOku Kei Ngangatu',
  },
  {
    page: 60,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/though-deepening-trials?lang=ton',
    title: 'Ka Faingataʻa Ho Hala',
  },
  {
    page: 61,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/father-in-heaven?lang=ton',
    title: 'ʻE Tamai ʻAfio ʻi Langi',
  },
  {
    page: 62,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/when-faith-endures?lang=ton',
    title: 'Ko e Tui Mateakí',
  },
  {
    page: 63,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/where-can-i-turn-for-peace?lang=ton',
    title: 'Te U Maʻu ʻI Fē ʻa e Melinó?',
  },
  {
    page: 64,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/more-holiness-give-me?lang=ton',
    title: 'Fakatapuʻi Au',
  },
  {
    page: 65,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/be-thou-humble?lang=ton',
    title: 'Ke Ke Loto-māʻulalo',
  },
  {
    page: 66,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-believe-in-christ?lang=ton',
    title: 'ʻOku Ou Tui Kai Kalaisi',
  },
  {
    page: 67,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/my-redeemer-lives?lang=ton',
    title: 'Ko Hoku Huhuʻi',
  },
  {
    page: 68,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-know-that-my-redeemer-lives?lang=ton',
    title: 'ʻOku Moʻui Hoku Huhuʻí',
  },
  {
    page: 69,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/testimony?lang=ton',
    title: 'Ko e Fakamoʻoní',
  },
  {
    page: 70,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/did-you-think-to-pray?lang=ton',
    title: 'Fai Haʻo Lotu',
  },
  {
    page: 71,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jesus-the-very-thought-of-thee?lang=ton',
    title: 'Sīsū, ko Hoku Maluʻi',
  },
  {
    page: 72,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/sweet-hour-of-prayer?lang=ton',
    title: 'Ko e Houa ke Lotu',
  },
  {
    page: 73,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/let-the-holy-spirit-guide?lang=ton',
    title: 'ʻOku Taki ʻa e Laumālié',
  },
  {
    page: 74,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/secret-prayer?lang=ton',
    title: 'Ko e Lotu Liló',
  },
  {
    page: 75,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/prayer-is-the-souls-sincere-desire?lang=ton',
    title: 'ʻEiki ke Ke Tali Muʻa',
  },
  {
    page: 76,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/gently-raise-the-sacred-strain?lang=ton',
    title: 'Ke Tau Ngāue Mālohi',
  },
  {
    page: 77,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/sweet-is-the-work?lang=ton',
    title: 'ʻE ʻOtua ko Homau Tuʻi',
  },
  {
    page: 78,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/of-all-blessings-received?lang=ton',
    title: 'Monū ka ko ha Monū ne Tō',
  },
  {
    page: 79,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/lord-we-ask-thee-ere-we-part?lang=ton',
    title: 'ʻO ka Mau ka Tutuku',
  },
  {
    page: 80,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/gather-the-wanderers-in?lang=ton',
    title: 'Naʻe ʻAfioʻi pē ʻe Sīsū',
  },
  {
    page: 81,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/as-the-dew-from-heaven-distilling?lang=ton',
    title: 'Ko e Hahau Tō mei he Langí',
  },
  {
    page: 82,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-be-with-you-till-we-meet-again?lang=ton',
    title: 'ʻOku ʻa e ʻOtua Kitaua',
  },
  {
    page: 83,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/now-the-day-is-over?lang=ton',
    title: 'Kuo ʻOsi ʻa e ʻAho',
  },
  {
    page: 84,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/before-thee-lord-i-bow-my-head?lang=ton',
    title: 'Hoku ʻEiki Te U Hū Atu',
  },
  {
    page: 85,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/softly-now-the-light-of-day?lang=ton',
    title: 'Mokomoko ʻo e ʻAhó',
  },
  {
    page: 86,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/abide-with-me-tis-eventide?lang=ton',
    title: 'Afe Mai Kuo Poʻuli',
  },
  {
    page: 87,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/abide-with-me?lang=ton',
    title: 'Afe Mai Hē',
  },
  {
    page: 88,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/sing-we-now-at-parting?lang=ton',
    title: 'ʻO ka Tau Tutuku Hiva Fiefia',
  },
  {
    page: 89,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/lord-dismiss-us-with-thy-blessing?lang=ton',
    title: 'ʻEiki ke Ke Tāpuaki',
  },
  {
    page: 90,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/in-humility-our-savior?lang=ton',
    title: 'Sīsū Homau Huhuʻi',
  },
  {
    page: 91,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-our-father-hear-us-pray?lang=ton',
    title: 'ʻOtua Tali ʻEmau Hū',
  },
  {
    page: 92,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/take-the-name-of-jesus-with-you?lang=ton',
    title: 'Ne Fai pē mei Kālevale',
  },
  {
    page: 93,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/while-of-these-emblems-we-partake-aeolian?lang=ton',
    title: 'ʻOku Mau Fakamanatu',
  },
  {
    page: 94,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-god-the-eternal-father?lang=ton',
    title: 'ʻE Tamai Taʻengata',
  },
  {
    page: 95,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jesus-of-nazareth-savior-and-king?lang=ton',
    title: 'Sīsū ʻo Nāsaleti',
  },
  {
    page: 96,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/he-who-was-crucified?lang=ton',
    title: 'Fakaʻofa Kiate Au',
  },
  {
    page: 97,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/well-sing-all-hail-to-jesus-name?lang=ton',
    title: 'Tau Fakamālō Kia Sīsū',
  },
  {
    page: 98,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jesus-who-breaketh-bread?lang=ton',
    title: 'Sīsū ē Naʻá Ne Paki',
  },
  {
    page: 99,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/upon-the-cross-of-calvary?lang=ton',
    title: 'Ne Kalusefai ʻa e ʻEikí',
  },
  {
    page: 100,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/behold-the-great-redeemer-die?lang=ton',
    title: 'ʻE Vakai Naʻe Pekia',
  },
  {
    page: 101,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/in-memory-of-the-crucified?lang=ton',
    title: 'Ke Tau Fakamanatu',
  },
  {
    page: 102,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-stand-all-amazed?lang=ton',
    title: 'ʻOku Fakaofo',
  },
  {
    page: 103,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/there-is-a-green-hill-far-away?lang=ton',
    title: 'ʻOku ʻi ai ha Fonua',
  },
  {
    page: 104,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/reverently-and-meekly-now?lang=ton',
    title: 'Tau Lotu pea Tau Hū',
  },
  {
    page: 105,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-great-the-wisdom-and-the-love?lang=ton',
    title: 'Naʻe Lahi Fau ʻa e ʻOfa',
  },
  {
    page: 106,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-loved-us-so-he-sent-his-son?lang=ton',
    title: 'Naʻe ʻOfa Pehē ʻa e ʻOtuá',
  },
  {
    page: 107,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jesus-once-of-humble-birth?lang=ton',
    title: 'Sīsū ē Ne ʻAloʻi',
  },
  {
    page: 108,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/he-is-risen?lang=ton',
    title: 'Kuo Toe Tuʻu',
  },
  {
    page: 109,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/christ-the-lord-is-risen-today?lang=ton',
    title: 'Kuo Toe Tuʻu ʻa e ʻEiki',
  },
  {
    page: 110,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/joy-to-the-world?lang=ton',
    title: 'Fiefia ki Māmani',
  },
  {
    page: 111,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/oh-come-all-ye-faithful?lang=ton',
    title: 'Mou Haʻu Kāinga',
  },
  {
    page: 112,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/angels-we-have-heard-on-high?lang=ton',
    title: 'Fanongoa Kau ʻĀngelo',
  },
  {
    page: 113,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/silent-night?lang=ton',
    title: 'Pō Mālū',
  },
  {
    page: 114,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/away-in-a-manger?lang=ton',
    title: 'Naʻe ʻAloʻi Mai ʻa Sīsū Kalaisi',
  },
  {
    page: 115,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/it-came-upon-the-midnight-clear?lang=ton',
    title: 'Ne Hā Mai Tuʻuapō',
  },
  {
    page: 116,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-little-town-of-bethlehem?lang=ton',
    title: 'Kolo ko Pētelihemá',
  },
  {
    page: 117,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/while-shepherds-watched-their-flocks?lang=ton',
    title: 'Lolotonga Leʻo ʻa e Kau Tauhi-sipi ʻi he Pō',
  },
  {
    page: 118,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/hark-the-herald-angels-sing?lang=ton',
    title: 'Kau, ʻĀngelo ne Hiva',
  },
  {
    page: 119,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/with-wondering-awe?lang=ton',
    title: 'Naʻe Ofo ʻa e Kau Potó',
  },
  {
    page: 120,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/far-far-away-on-judeas-plains?lang=ton',
    title: 'ʻA e Kau Tauhi-sipi ʻi Siutea',
  },
  {
    page: 121,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-heard-the-bells-on-christmas-day?lang=ton',
    title: 'Ne U Ongoʻi ʻa e Ngaahi Fafangú',
  },
  {
    page: 122,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-first-noel?lang=ton',
    title: 'Hiva ʻa e Kau ʻĀngeló',
  },
  {
    page: 123,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-are-sowing?lang=ton',
    title: 'ʻOku Tau Tō ʻa e Tenga',
  },
  {
    page: 124,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-let-us-anew?lang=ton',
    title: 'Tau Fakaake',
  },
  {
    page: 125,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-give-thee-but-thine-own?lang=ton',
    title: 'ʻOku Mau Hū Atu',
  },
  {
    page: 126,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/because-i-have-been-given-much?lang=ton',
    title: 'Kuo Lahi Hoku Tāpuakí',
  },
  {
    page: 127,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/lord-i-would-follow-thee?lang=ton',
    title: 'ʻEiki, te U Muimui Atu',
  },
  {
    page: 128,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/dear-to-the-heart-of-the-shepherd?lang=ton',
    title: 'ʻOku ʻOfa ʻa e Tauhi',
  },
  {
    page: 129,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/have-i-done-any-good?lang=ton',
    title: 'Kuó U Fai ha Lelei?',
  },
  {
    page: 130,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-have-work-enough-to-do?lang=ton',
    title: 'Ko e Ngāue ke Fai',
  },
  {
    page: 131,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-are-marching-on-to-glory?lang=ton',
    title: 'Tau Tuku Kolōlia',
  },
  {
    page: 132,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/improve-the-shining-moments?lang=ton',
    title: 'Tokanga ki he Taimi',
  },
  {
    page: 133,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/there-is-sunshine-in-my-soul-today?lang=ton',
    title: 'Lolotonga ʻEku Nofo',
  },
  {
    page: 134,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/you-can-make-the-pathway-bright?lang=ton',
    title: 'Te Ke Lava Fakahā',
  },
  {
    page: 135,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/today-while-the-sun-shines?lang=ton',
    title: 'Ko e ʻAho ke Ngāue he ʻOfa',
  },
  {
    page: 136,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/scatter-sunshine?lang=ton',
    title: 'ʻI he Māmani mo e Faingataʻa',
  },
  {
    page: 137,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jesus-mighty-king-in-zion?lang=ton',
    title: 'Sīsū Tuʻi Māfimafi',
  },
  {
    page: 138,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/catch-the-sunshine?lang=ton',
    title: 'Kuo Hopo ʻa e Laʻā',
  },
  {
    page: 139,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/this-your-inheritance?lang=ton',
    title: 'Lau ʻEni ko e Tofiʻa',
  },
  {
    page: 140,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/should-you-feel-inclined-to-censure?lang=ton',
    title: 'ʻOua te Ke Fakamaau',
  },
  {
    page: 141,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/angry-words-oh-let-them-never?lang=ton',
    title: 'Lea ʻIta ʻOua Naʻa Tuku ke Puna Noa pē',
  },
  {
    page: 142,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/let-us-oft-speak-kind-words?lang=ton',
    title: 'Kapau te Tau Feʻofoʻofani',
  },
  {
    page: 143,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/nay-speak-no-ill?lang=ton',
    title: 'ʻOku Lelei ʻa e Lea Vaivai',
  },
  {
    page: 144,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/as-i-search-the-holy-scriptures?lang=ton',
    title: 'Kumi ki Hoʻo Folofolá',
  },
  {
    page: 145,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/do-what-is-right?lang=ton',
    title: 'Faitotonu Neongo ʻa e Luma',
  },
  {
    page: 146,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/choose-the-right?lang=ton',
    title: 'Fili ʻa e Moʻoní',
  },
  {
    page: 147,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/praise-god-from-whom-all-blessings-flow?lang=ton',
    title: 'Fakamālō he Tāpuaki',
  },
  {
    page: 148,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/count-your-blessings?lang=ton',
    title: 'Tānaki Ho Ngaahi Tāpuaki',
  },
  {
    page: 149,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/let-us-all-press-on?lang=ton',
    title: 'Tuku ke Tau Faimālohi',
  },
  {
    page: 150,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/onward-christian-soldiers?lang=ton',
    title: 'Laka Atu ʻe Kāinga ki he Tau',
  },
  {
    page: 151,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/up-awake-ye-defenders-of-zion?lang=ton',
    title: 'ʻĀ Hake Kau Leʻo ʻo Saione',
  },
  {
    page: 152,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/we-are-all-enlisted?lang=ton',
    title: 'ʻE ʻOtua Māfimafi ʻAfio Mai',
  },
  {
    page: 153,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/called-to-serve?lang=ton',
    title: 'Fanongo ko e ʻEiki ʻoku Ui',
  },
  {
    page: 154,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/behold-a-royal-army?lang=ton',
    title: 'ʻA e Kau Tau ʻa e ʻOtua',
  },
  {
    page: 155,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/put-your-shoulder-to-the-wheel?lang=ton',
    title: 'ʻE ʻAonga ki Māmani ha Kau Ngāue Lelei',
  },
  {
    page: 156,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/thy-servants-are-prepared?lang=ton',
    title: 'Ko Hoʻo Kau Tamaioʻeikí',
  },
  {
    page: 157,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/true-to-the-faith?lang=ton',
    title: 'Tauhi ʻa e Moʻoní',
  },
  {
    page: 158,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/carry-on?lang=ton',
    title: 'Fai Atu ʻa e Ngāué',
  },
  {
    page: 159,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/as-zions-youth-in-latter-days?lang=ton',
    title: 'Kau Talavou ʻo Saione',
  },
  {
    page: 160,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-thou-rock-of-our-salvation?lang=ton',
    title: 'Sīsū ne Ne Huhuʻi',
  },
  {
    page: 161,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/hope-of-israel?lang=ton',
    title: 'ʻE Kāinga Kuo Hao',
  },
  {
    page: 162,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/go-forth-with-faith?lang=ton',
    title: 'Laka he Tui pea Fakahā',
  },
  {
    page: 163,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/whos-on-the-lords-side?lang=ton',
    title: 'Tau Nofo he ʻEiki Hono Tafaʻaki',
  },
  {
    page: 164,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/hark-all-ye-nations?lang=ton',
    title: 'Ke Fiefia ʻa Māmani',
  },
  {
    page: 165,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/jehovah-lord-of-heaven-and-earth?lang=ton',
    title: 'Sihova, ʻEiki ʻo Langi mo Māmani',
  },
  {
    page: 166,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-time-is-far-spent?lang=ton',
    title: 'ʻO ka Tau ka Fakaʻaongaʻi ʻa e Taimi',
  },
  {
    page: 167,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-wondrous-and-great?lang=ton',
    title: 'ʻOku Fakaofo Hoʻo Ngāué',
  },
  {
    page: 168,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/oh-holy-words-of-truth-and-love?lang=ton',
    title: 'Tala ʻe he Folofola',
  },
  {
    page: 169,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/oh-say-what-is-truth?lang=ton',
    title: 'Ko e Faitotonu ʻo Faka-ʻOtua',
  },
  {
    page: 170,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/truth-reflects-upon-our-senses?lang=ton',
    title: 'ʻOku Aata ʻa e Moʻoní',
  },
  {
    page: 171,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/ill-go-where-you-want-me-to-go?lang=ton',
    title: 'Te U Fai Ho Finangaló',
  },
  {
    page: 172,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-iron-rod?lang=ton',
    title: 'Ko e Vaʻa Ukameá',
  },
  {
    page: 173,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/gladly-meeting?lang=ton',
    title: 'Fetaulaki Fiefia',
  },
  {
    page: 174,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-away-to-the-sunday-school?lang=ton',
    title: 'Haʻu ki he Lautohi Faka-Sāpate',
  },
  {
    page: 175,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/welcome-welcome-sabbath-morning?lang=ton',
    title: 'Siʻoto ʻOfa Sāpate',
  },
  {
    page: 176,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/help-me-teach-with-inspiration?lang=ton',
    title: 'Tokoni Mai ke U Akoʻi',
  },
  {
    page: 177,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/if-you-could-hie-to-kolob?lang=ton',
    title: 'Ka ne Ke ʻAlu ki Kolapa',
  },
  {
    page: 178,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-moves-in-a-mysterious-way?lang=ton',
    title: 'ʻOku ʻAlu Fano ʻa e ʻOtua',
  },
  {
    page: 179,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/oh-what-songs-of-the-heart?lang=ton',
    title: 'Ko e Hiva ʻo e Lotó',
  },
  {
    page: 180,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/rise-ye-saints-and-temples-enter?lang=ton',
    title: 'Kāinga Tau Hū he Temipale',
  },
  {
    page: 181,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/how-beautiful-thy-temples-lord?lang=ton',
    title: 'ʻOku Fakaʻofoʻofa ʻa e Fale ʻo e ʻEikí',
  },
  {
    page: 182,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/turn-your-hearts?lang=ton',
    title: 'Liliu Homou Lotó',
  },
  {
    page: 183,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/let-us-therefore-take-heed?lang=ton',
    title: 'Ke Tau Tokanga Muʻa',
  },
  {
    page: 184,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/god-is-willing-to-gather?lang=ton',
    title: 'Fakahā ʻe he ʻOtua',
  },
  {
    page: 185,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-my-father?lang=ton',
    title: 'ʻE Tamai ʻi Hēvani ē',
  },
  {
    page: 186,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/each-life-that-touches-ours-for-good?lang=ton',
    title: 'Ko e Taki ki he Lelei',
  },
  {
    page: 187,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/love-at-home?lang=ton',
    title: 'ʻOfa ʻi ʻApi',
  },
  {
    page: 188,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/home-can-be-a-heaven-on-earth?lang=ton',
    title: 'Hoko ʻa ʻApi ko e Hēvani',
  },
  {
    page: 189,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/o-love-that-glorifies-the-son?lang=ton',
    title: 'ʻA e ʻOfa Taau mo e ʻAló',
  },
  {
    page: 190,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/dearest-children-god-is-near-you?lang=ton',
    title: 'ʻE Fānau ko e ʻOtua ē',
  },
  {
    page: 191,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/children-of-our-heavenly-father?lang=ton',
    title: 'Fānau ʻEtau Tamai ʻi Hēvaní',
  },
  {
    page: 192,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/families-can-be-together-forever?lang=ton',
    title: 'Ko e Fāmilí ke Taʻe Ngata',
  },
  {
    page: 193,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-am-a-child-of-god?lang=ton',
    title: 'Fānau Au ʻa e ʻOtua',
  },
  {
    page: 194,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/i-know-my-father-lives?lang=ton',
    title: 'ʻOku Moʻui ʻEku Tamaí',
  },
  {
    page: 195,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/keep-the-commandments?lang=ton',
    title: 'Tauhi ʻa e Ngaahi Fekaú',
  },
  {
    page: 196,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/teach-me-to-walk-in-the-light?lang=ton',
    title: 'Akoʻi Au ke U ʻEva he Māmá',
  },
  {
    page: 197,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/the-light-divine?lang=ton',
    title: 'Ko e Maama Faka-ʻOtuá',
  },
  {
    page: 198,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/gods-daily-care?lang=ton',
    title: 'Ko e ʻOfa ʻa e ʻOtuá',
  },
  {
    page: 199,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/in-our-lovely-deseret?lang=ton',
    title: 'ʻOku ai ʻi Māmani',
  },
  {
    page: 200,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/love-one-another?lang=ton',
    title: 'Ke Mou Feʻofaʻaki',
  },
  {
    page: 201,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/as-sisters-in-zion?lang=ton',
    title: 'Kau Fefine ʻo Saioné',
  },
  {
    page: 202,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/ye-elders-of-israel?lang=ton',
    title: 'ʻA Kimoutolu ʻa e Kau Faifekau',
  },
  {
    page: 203,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/ye-who-are-called-to-labor?lang=ton',
    title: 'Kuo Ui Kimoutolu',
  },
  {
    page: 204,
    link:
      'https://www.churchofjesuschrist.org/media/music/songs/come-all-ye-sons-of-god?lang=ton',
    title: 'Mou Haʻu Kātoa Mai',
  },
];

export default HYMNS_TON;
