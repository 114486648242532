import { Hymn, HymnLanguage } from '../models/hymn';
import HYMNS_ENG from './hymns.eng';
import HYMNS_TON from './hymns.ton';

export const getHymn = (page: number, lang: HymnLanguage): Hymn => {
  if (lang === HymnLanguage.english) {
    const targetHymn = HYMNS_ENG.find((i) => i.page === page);
    return {
      title: targetHymn.title,
      page: targetHymn.page,
      link: targetHymn.link,
      language: HymnLanguage.english,
    };
  }

  if (lang === HymnLanguage.tongan) {
    const targetHymn = HYMNS_TON.find((i) => i.page === page);
    return {
      title: targetHymn.title,
      page: targetHymn.page,
      link: targetHymn.link,
      language: HymnLanguage.tongan,
    };
  }
};

export default {
  getHymn,
};
