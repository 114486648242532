export enum HymnLanguage {
  english = 'English',
  tongan = 'Tongan',
}

export interface Hymn {
  page: number;
  title: string;
  link: string;
  language: HymnLanguage;
}

export interface HymnsObject {
  [key: string]: Hymn;
}
